<script setup lang="ts">
import { CfgPreloader } from '@cfgtech/ui'
import { useHomepageBlogPreview } from '~/composables/blog/useHomepageBlogPreview'
import type { SbBlogPostsStoryblok } from '~/types/storyblok'

defineProps<{ blok: SbBlogPostsStoryblok }>()

const { posts, fetchPosts } = useHomepageBlogPreview()

onMounted(() => {
  if (!posts.value?.length) {
    fetchPosts()
  }
})
</script>

<template>
  <!-- -mx-5 to counter container -->
  <div v-editable="blok" class="sb_blog_posts -mx-5 flex items-center justify-center">
    <div v-if="posts?.length" class="w-full">
      <BlogPreviewCards :posts="posts" />
    </div>

    <CfgPreloader v-else class="text-lg text-brand" />
  </div>
</template>
